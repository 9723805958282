import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import ElementUI from "element-ui";
import MetaInfo from "vue-meta-info";
import "element-ui/lib/theme-chalk/index.css";
import Vant from "vant";
import "vant/lib/index.css";
//import HzComponents from "./components/hz";
//import "./css/hz.css";
import store from "./store";
// 引入 axios
import axios from "axios";
// 挂载一个自定义属性$http
Vue.prototype.axios = axios;
Vue.use(HzComponents);

Vue.use(ElementUI);
Vue.use(Vant);
Vue.use(MetaInfo);
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
