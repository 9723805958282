<!-- 顶部表单 -->
<template>
  <div :class="formStyle ? 'form-box form' : 'form-inline form'">
    <template v-if="show == '1'">
      <div class="title" v-if="title">预约使用</div>

      <el-form :model="form" :rules="rules" ref="formRef" :inline="inline">
        <el-form-item prop="companyname">
          <el-autocomplete :trigger-on-focus="false" @focus="iscompany = 1" @blur="companyNameBlur()" :debounce=debounce
            v-model="form.companyname" :fetch-suggestions="querySearchAsync" @select="handleSelect"
            placeholder="请输入单位名称">
            <template slot-scope="{item}">
              <div v-html="item.value">{{item.value}}</div>
            </template>
            <span slot="prefix">
              <div v-if="iscompany == 2" class="company"></div>
              <div v-else class="company-blue"></div>
            </span>
          </el-autocomplete>
        </el-form-item>

        <el-form-item prop="phone">
          <el-input @focus="isfocus = 1" @blur="isfocus = 2" placeholder="请输入手机号" v-model="form.phone">
            <span slot="prefix">
              <div v-if="isfocus == 2" class="phone"></div>
              <div v-else class="phone-blue"></div>
            </span>
          </el-input>
        </el-form-item>
        <el-form-item prop="checkCode" class="last">
          <el-input @focus="focuscode = 1" @blur="focuscode = 2" placeholder="请输入验证码" v-model="form.checkCode">
            <span slot="prefix">
              <div v-if="focuscode == 2" class="safenum"></div>
              <div v-else class="safenum-blue"></div>
            </span>
            <span v-if="showCode" slot="suffix" @click="sendCode">发送验证码</span>
            <span v-else slot="suffix">{{ timeNum }}</span>
          </el-input>
        </el-form-item>
        <el-form-item class="action-btn">
          <el-button type="primary" class="start-btn" @click="sendFormFn">开始体验</el-button>
        </el-form-item>
      </el-form>
    </template>
    <template v-else>
      <div class="success-box">
        <img src="@/assets/img/zu.png" alt="">
        <div class="text-1">提交成功</div>
        <div class="text-2">我们会在3个工作日内与您联系,请注意接听来电</div>
        <el-button class="btn" type="primary" @click="successFn">好的</el-button>
      </div>
    </template>

  </div>
</template>
<script>
import { getPhoneCode, saveForm, getCompanyName } from '@/utils/api'

export default {
  name: 'topform',
  components: {},
  created() {

  },
  props: {
    //行内属性
    inline: {
      default: false
    },
    title: {
      default: true
    },
    //行内样式盒子
    formStyle: {
      default: true
    }
  },
  data() {
    return {
      isfocus: 2,
      focuscode: 2,
      iscompany: 2,
      form: {
        registerProduct: '特殊作业票管理系统',
        interestProduct: '特殊作业票管理系统',
        concreteDemand: '想要了解此产品',
        productId: '660894320710393856',

      },
      debounce: 500,
      rules: {
        companyname: [{ required: true, trigger: 'change' },],
        phone: [{ required: true, trigger: 'blur' },],
        checkCode: [{ required: true, trigger: 'blur' },],
      },
      timeNum: 60,
      showCode: true,
      timeOut: null,
      show: '1',
      isChoose: false // 是否是选择的单位名称
    }
  },
  methods: {
    //获取验证码
    sendCode() {
      let that = this
      let num = that.form.phone
      var pattern = /0?(13|14|15|17|18|19)[0-9]{9}/
      let result = pattern.test(num)
      if (!result) {
        that.$message.warning('无效手机号')
      } else {
        let data = {}
        data.phoneNumber = that.form.phone
        getPhoneCode(data).then(res => {
          that.timeNum = 60
          that.showCode = false
          clearInterval(that.timeOut)
          that.timeOut = setInterval(() => {
            if (that.timeNum == 1) {
              that.showCode = true
            }
            that.timeNum--
          }, 1000)
        })

      }

    },
    handleSelect(value) {
      this.isChoose = true;
      this.form.companyname = value.name
    },
    sendFormFn() {

      let that = this
      /* 测试注册成功后的展示效果 */
      /* if (!that.formStyle) {
          that.$message.success('注册成功，稍后会有工作人员与您取得联系')
      } else {
          that.show = '2'
      } */
      that.$refs['formRef'].validate((valid) => {
        if (valid) {
          // 判断用户如何进入网站  
          var campaignName;
          // 前一个网站的地址
          var beforeUrl = document.referrer;
          // 当前网站地址
          var nowUrl = window.location.href;
          // 1.如果当前地址栏有参数pk_campaign且有值，则为广告推广进入  
          if (nowUrl.indexOf('pk_campaign') !== -1) {
            // 获取查询参数  
            let searchParams = new URLSearchParams(nowUrl.split('?')[1]);
            let pkCampaign = searchParams.get('pk_campaign'); //获取pk_campaign 的值  
            if (pkCampaign) {
              campaignName = pkCampaign;
            }
          }
          // 2.前一个页面地址为空且此时campaignName值不存在，则为地址栏直接进入
          if (!beforeUrl && !campaignName) {
            // 直接地址栏进入  
            campaignName = 'directaccess';
            // 3.如果前一个页面地址不为空且此时campaignName值依然不存在，则为自然搜素进入
          } else if (!campaignName) {
            // 自然搜索进入  
            var start = beforeUrl.indexOf('.') + 1;
            var end = beforeUrl.indexOf('.', start);
            if (start && end) {
              var result = beforeUrl.substring(start, end);
              if (result === 'baidu') {
                campaignName = 'organic-search-baidu';
              } else if (result === 'so') {
                campaignName = 'organic-search-360';
              } else if (result === 'google') {
                campaignName = 'organic-search-google';
              } else if (result === 'sogou') {
                campaignName = 'organic-search-sogou';
              } else if (result === 'bing') {
                campaignName = 'organic-search-bing';
              } else {
                campaignName = 'organic-search-other';
              }
            }
          }
          that.form.campaignName = campaignName
          // saveForm(that.form).then(res => {
          //   if (res.data == '注册成功') {
          //     clearInterval(that.timeOut)
          //     that.showCode = true
          //     that.form = {}
          //     that.$refs['formRef'].clearValidate()
          //     if (!that.formStyle) {
          //       that.$message.success('注册成功，稍后会有工作人员与您取得联系')
          //     } else {
          //       that.show = '2'
          //     }
          //   } else {
          //     that.$message.warning(res.data)
          //   }
          // }).catch(err => {
          //   that.$message.error(err)
          // })
        } else {
          return false
        }
      })

    },
    querySearchAsync(data, cb) {
      let that = this;
      if (data) {
        that.isChoose = false;
        getCompanyName({ word: data }).then(res => {
          if (res.status == 200) {
            if (res.data.result) {
              let arr = res.data.result.items
              let newHtml = `<span style="color:brown">${data}</span>`
              arr.forEach(item => {
                item.value = item.name.replace(data, newHtml)
              })
              cb(arr)
            } else {
              console.log('没有数据');
            }
          } else {
            that.$message.warning(res.msg)
          }
        }).catch(err => {
          that.$message.error(err)
        })
      }
    },
    successFn() {
      this.show = '1'
    },

    // 单位名称输入框取消选中状态
    companyNameBlur() {
      this.iscompany = 2;
      if (!this.isChoose) {
        this.form.companyname = ''
      }
    }
  }
}

</script>
<style scoped lang="less">
.form {
  span {
    font-size: 14px;
    cursor: pointer;
    color: #2377FF;
  }


  .title {
    text-align: center;
    font-size: 20px;
    font-family: PingFang SC-Bold, PingFang SC;
    font-weight: bold;
    color: #2E2E2E;
    line-height: 48px;
    margin-bottom: 15px;
  }

  .company {
    display: inline-block;
    background-image: url(../assets/img/form1white.png);
    width: 16px;
    height: 16px;
    background-size: 100% 100%;
  }

  .company-blue {
    display: inline-block;
    background-image: url(../assets/img/gongsiicon.png);
    width: 16px;
    height: 16px;
    background-size: 100% 100%;
  }

  .phone {
    display: inline-block;
    background-image: url(../assets/img/phone.png);
    width: 12px;
    height: 17px;
    background-size: 100% 100%;
  }

  .phone-blue {
    display: inline-block;
    background-image: url(../assets/img/phoneicon.png);
    width: 12px;
    height: 17px;
    background-size: 100% 100%;
  }

  .safenum {
    display: inline-block;
    background-image: url(../assets/img/safenum.png);
    width: 14px;
    height: 16px;
    background-size: 100% 100%;
  }

  .safenum-blue {
    display: inline-block;
    background-image: url(../assets/img/safeicon.png);
    width: 14px;
    height: 16px;
    background-size: 100% 100%;
  }
}

.form-box {
  width: 380px;
  height: 342px;
  min-height: 308px;
  background: rgba(255, 255, 255, 0.9);
  border-radius: 5px 5px 5px 5px;
  opacity: 0.95;
  padding: 20px 33px 41px 33px;
  box-sizing: border-box;

  .start-btn {
    margin-top: 10px;
    width: 314px;
    height: 48px;
    background: #2377FF;
    box-shadow: 0px 3px 6px 1px rgba(4, 61, 219, 0.34);
    border-radius: 5px 5px 5px 5px;

  }


  ::v-deep .el-input__prefix {
    top: 3px;
  }


}

.form-inline {
  width: 100%;
  margin-top: 40px;
  text-align: center;
  font-size: 16px;

  .start-btn {
    width: 162px;
    height: 50px;
    background: #2377FF;
    background-size: cover;
    border-radius: 6px;
    font-size: 16px;
  }

  .start-btn:hover {
    opacity: 0.9;
    width: 162px;
    height: 50px;
    background: #2377FF;
    background-size: cover;
    border-radius: 6px;
    font-size: 16px;
  }

  .el-button {
    border: none;
  }

  ::v-deep .el-input__inner {
    width: 308px;
    height: 50px;
  }

  .last {
    ::v-deep .el-input__inner {
      width: 308px;
    }
  }

  .action-btn {
    margin-right: 0 !important;

    ::v-deep .el-input__inner:last-child {
      width: 100%;
    }
  }

  ::v-deep .el-input__prefix {
    top: 8px;
  }

  ::v-deep .el-input__suffix {
    top: 5px;
  }


}

.success-box {
  margin-top: 18px;
  text-align: center;

  img {
    width: 90px;
  }

  .text-1 {
    font-size: 28px;
    color: #202124;
    margin-top: 10px;
    margin-bottom: 20px;
    box-sizing: border-box;

  }

  .text-2 {
    font-style: 18px;
    color: #202124;
    margin-bottom: 20px;
    box-sizing: border-box;

  }

  .btn {
    background: linear-gradient(90deg, #018DFF 0%, #1A74F1 100%);
    border-radius: 5px 5px 5px 5px;
  }
}

::v-deep .el-form-item__error {
  display: none;
}

.el-form-item {
  margin-bottom: 12px;
}

.el-autocomplete {
  width: 100%
}

::v-deep .el-input__suffix {
  right: 10px;
}

::v-deep .el-input__prefix {
  left: 10px;
}

.el-form--inline .el-form-item {
  margin-right: 35px;
}
</style>
