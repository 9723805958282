//获取热门推荐
import axios from "axios";
let BSAE_RUL = "";
if (process.env.NODE_ENV == "development") {
  BSAE_RUL = "/api";
} else if (process.env.NODE_ENV == "production") {
  BSAE_RUL = "/api";
}
axios.defaults.baseURL = BSAE_RUL;
//获取验证码
export function getPhoneCode(params) {
  return new Promise((resolve, reject) => {
    axios
      .postForm("/sendPhoneCode.jspx", params)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
export function saveForm(params) {
  return new Promise((resolve, reject) => {
    axios
      .postForm("/saveForm.jspx", params)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
/* 查询企业名称 */
export function getCompanyName(params) {
  return new Promise((resolve, reject) => {
    axios
      .postForm("/queryCompanyName.jspx", params)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
