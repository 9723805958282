<template>
  <van-popup v-model="visible" round position="bottom" class="app_pop">
    <div class="dialog-main">
      <div class="dialog_fill" v-if="show == 1">
        <div class="pop_title">立即体验</div>
        <el-form
          ref="ruleForm"
          label-position="right"
          label-width="80px"
          :model="ruleForm"
          :rules="rules"
          class="app_form"
        >
          <el-form-item prop="companyname">
            <el-autocomplete
              placeholder="您的单位名称"
              v-model="ruleForm.companyname"
              :trigger-on-focus="false"
              ref="companyname"
              @blur="companyNameBlur()"
              :debounce="debounce"
              :fetch-suggestions="querySearchAsync"
              @select="handleSelect"
            >
              <template slot-scope="{ item }">
                <div v-html="item.value">{{ item.value }}</div>
              </template>
              <div slot="prefix" class="formicon">
                <span>
                  <img
                    src="@/assets/img/app_adaptation/dialog_company.svg"
                    height="17"
                  />
                </span>
                <span></span>
              </div>
            </el-autocomplete>
          </el-form-item>
          <el-form-item prop="usename">
            <el-input placeholder="您的姓名" v-model="ruleForm.usename">
              <div slot="prefix" class="formicon">
                <span>
                  <img
                    src="@/assets/img/app_adaptation/dialog_name.svg"
                    height="17"
                  />
                </span>
                <span></span>
              </div>
            </el-input>
          </el-form-item>
          <el-form-item prop="phone">
            <el-input placeholder="您的联系方式" v-model="ruleForm.phone">
              <div slot="prefix" class="formicon">
                <span>
                  <img
                    src="@/assets/img/app_adaptation/dialog_phone.svg"
                    height="17"
                  />
                </span>
                <span></span>
              </div>
            </el-input>
          </el-form-item>
          <el-form-item prop="checkCode">
            <el-input placeholder="请输入验证码" v-model="ruleForm.checkCode">
              <div slot="prefix" class="formicon">
                <span>
                  <img
                    src="@/assets/img/app_adaptation/dialog_checkCode.svg"
                    height="17"
                  />
                </span>
                <span></span>
              </div>
              <span
                v-if="showCode"
                slot="suffix"
                class="checkCode"
                @click="sendCode"
                >发送验证码</span
              >
              <span v-else class="checkCode" slot="suffix">{{ timeNum }}</span>
            </el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="experience" class="submit_btn"
              >开始体验</el-button
            >
          </el-form-item>
        </el-form>
      </div>
      <div class="dialog_success" v-if="show == 2">
        <div class="dialog_img_box">
          <img
            src="@/assets/img/app_adaptation/submit_success.svg"
            height="80"
          />
        </div>
        <div class="dialog_text_box">
          <div class="text_title">提交成功</div>
          <div class="text_content">
            我们会在3个工作日内与您联系，请注意接听来电
          </div>
        </div>
        <el-button type="primary" @click="successFn" class="submit_btn"
          >好的</el-button
        >
      </div>
      <!--      <van-form @submit="onSubmit" class="app_form">-->
      <!--        <van-field-->
      <!--          class="pop_field"-->
      <!--          v-model="ruleForm.usename"-->
      <!--          name="用户名"-->
      <!--          left-icon="pop_name"-->
      <!--          placeholder="您的姓名"-->
      <!--          :rules="[{ required: true, message: '请填写用户名' }]"-->
      <!--        />-->
      <!--        <van-field-->
      <!--          class="pop_field"-->
      <!--          v-model="ruleForm.companyname"-->
      <!--          name="单位名称"-->
      <!--          left-icon="pop_company"-->
      <!--          placeholder="您的单位名称"-->
      <!--          :rules="[{ required: true, message: '请填写联系方式' }]"-->
      <!--        />-->
      <!--        <van-field-->
      <!--          class="pop_field"-->
      <!--          v-model="ruleForm.phone"-->
      <!--          name="联系方式"-->
      <!--          left-icon="pop_phone"-->
      <!--          placeholder="您的联系方式"-->
      <!--          :rules="[{ required: true, message: '请填写联系方式' }]"-->
      <!--        />-->
      <!--        <div style="margin: 16px;">-->
      <!--          <van-button round block type="info" native-type="submit">开始体验</van-button>-->
      <!--        </div>-->
      <!--      </van-form>-->
    </div>
  </van-popup>
</template>
<script>
import { getCompanyName, saveForm, getPhoneCode } from "@/utils/api";
import { Toast } from "vant";
export default {
  name: "",
  components: {},
  props: {
    dialogVisible: {
      type: Boolean,
      default: false,
    },
    // url: {
    //   type: String,
    //   default: '',
    // },
    // frameId: {
    //   type: String,
    //   default: '',
    // },
  },
  watch: {
    dialogVisible: function (nelVal, oldVal) {
      if (nelVal) {
        this.visible = nelVal;
      }
    },
    visible: function (newData, oldData) {
      if (!newData) {
        this.ruleForm = {
          usename: "",
          companyname: "",
          phone: "",
          checkCode: "",
          registerProduct: "特殊作业票管理系统",
          interestProduct: "特殊作业票管理系统",
          concreteDemand: "想要了解此产品",
          productId: "660894320710393856",
        };
        this.$refs.ruleForm.clearValidate();
      }
    },
    scrollWidth: function (newData, oldData) {
      let newJudge = newData <= 480;
      let oldJudge = newData <= 480;
      if (newJudge != oldJudge) {
        this.visible = false;
      }
    },
  },

  data() {
    let validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入密码"));
      } else {
        let regIdCard =
          /^1([38][0-9]|4[014-9]|[59][0-35-9]|6[2567]|7[0-8])\d{8}$/;
        if (!regIdCard.test(value)) {
          this.ruleForm.phone = "";
          this.$message.warning("请输入有效的联系方式");
          callback(new Error("请输入有效的联系方式"));
        } else {
          callback();
        }
      }
    };
    return {
      show: 1, //展示的弹窗页面
      ruleForm: {
        usename: "",
        companyname: "",
        phone: "",
        checkCode: "",
        registerProduct: "特殊作业票管理系统",
        interestProduct: "特殊作业票管理系统",
        concreteDemand: "想要了解此产品",
        productId: "660894320710393856",
      },
      rules: {
        usename: [{ required: true, trigger: "blur" }],
        companyname: [{ required: true, trigger: "blur" }],
        phone: [{ required: true, validator: validatePass, trigger: "blur" }],
      },
      visible: false,
      debounce: 500,
      isChoose: false, // 是否是选择的单位名称
      //行内样式盒子
      formStyle: {
        default: true,
      },
      scrollWidth: document.documentElement.clientWidth,
      // form: {
      //   registerProduct: '特殊作业票管理系统',
      //   interestProduct: '特殊作业票管理系统',
      //   concreteDemand: '想要了解此产品'
      // },
      // rules: {
      //   companyname: [{required: true, message: '请输入单位名称', trigger: 'change'},],
      //   phone: [{required: true, message: '请输入联系方式', trigger: 'blur'},],
      //   checkCode: [{required: true, message: '请输入验证码', trigger: 'blur'},],
      // },
      // show: '1',
      showCode: true,
      timeNum: 60,
      timeOut: "",
      // debounce: 500,
      // visible: false,
      // showUrl:''
    };
  },
  methods: {
    //获取验证码
    sendCode() {
      let that = this;
      let num = that.ruleForm.phone;
      var pattern = /0?(13|14|15|17|18|19)[0-9]{9}/;
      let result = pattern.test(num);
      if (!result) {
        that.$message.warning("无效手机号");
      } else {
        let data = {};
        data.phoneNumber = that.ruleForm.phone;
        getPhoneCode(data).then((res) => {
          that.timeNum = 60;
          that.showCode = false;
          clearInterval(that.timeOut);
          that.timeOut = setInterval(() => {
            if (that.timeNum == 1) {
              that.showCode = true;
            }
            that.timeNum--;
          }, 1000);
        });
      }
    },
    validateFn() {
      return new Promise((resolve, reject) => {
        this.$refs.ruleForm.validate((valid) => {
          if (valid) {
            resolve(true);
          } else {
            resolve(false);
            return false;
          }
        });
      });
    },
    //开始体验
    async experience() {
      const flag = await this.validateFn();
      if (!flag) return;
      // 判断用户如何进入网站
      var campaignName;
      // 前一个网站的地址
      var beforeUrl = document.referrer;
      // 当前网站地址
      var nowUrl = window.location.href;
      // 1.如果当前地址栏有参数pk_campaign且有值，则为广告推广进入
      if (nowUrl.indexOf("pk_campaign") !== -1) {
        // 获取查询参数
        let searchParams = new URLSearchParams(nowUrl.split("?")[1]);
        let pkCampaign = searchParams.get("pk_campaign"); //获取pk_campaign 的值
        if (pkCampaign) {
          campaignName = pkCampaign;
        }
      }
      // 2.前一个页面地址为空且此时campaignName值不存在，则为地址栏直接进入
      if (!beforeUrl && !campaignName) {
        // 直接地址栏进入
        campaignName = "directaccess";
        // 3.如果前一个页面地址不为空且此时campaignName值依然不存在，则为自然搜素进入
      } else if (!campaignName) {
        // 自然搜索进入
        var start = beforeUrl.indexOf(".") + 1;
        var end = beforeUrl.indexOf(".", start);
        if (start && end) {
          var result = beforeUrl.substring(start, end);
          if (result === "baidu") {
            campaignName = "organic-search-baidu";
          } else if (result === "so") {
            campaignName = "organic-search-360";
          } else if (result === "google") {
            campaignName = "organic-search-google";
          } else if (result === "sogou") {
            campaignName = "organic-search-sogou";
          } else if (result === "bing") {
            campaignName = "organic-search-bing";
          } else {
            campaignName = "organic-search-other";
          }
        }
      }
      this.ruleForm.campaignName = campaignName;
      saveForm(this.ruleForm)
        .then((res) => {
          if (res.data == "注册成功") {
            clearInterval(this.timeOut);
            this.ruleForm = {
            registerProduct: "特殊作业票管理系统",
              interestProduct: "特殊作业票管理系统",
              concreteDemand: "想要了解此产品",
              usename: "",
              companyname: "",
              phone: "",
              checkCode: "",
            };
            this.$refs.ruleForm.clearValidate();
            if (!this.formStyle) {
              Toast.success("注册成功，稍后会有工作人员与您取得联系");
            } else {
              this.show = "2";
            }
          } else {
            Toast.fail(res.data);
          }
        })
        .catch((err) => {
          Toast.fail(err);
        });
    },
    successFn() {
      this.visible = false;
      this.show = 1;
    },
    // 单位名称输入框取消选中状态
    companyNameBlur() {
      if (!this.isChoose) {
        this.ruleForm.companyname = "";
      }
    },
    querySearchAsync(data, cb) {
      let that = this;
      if (data) {
        that.isChoose = false;
        getCompanyName({ word: data })
          .then((res) => {
            if (res.status == 200) {
              if (res.data.result) {
                let arr = res.data.result.items;
                let newHtml = `<span style="color:brown">${data}</span>`;
                arr.forEach((item) => {
                  item.value = item.name.replace(data, newHtml);
                });
                cb(arr);
              } else {
                console.log("没有数据");
              }
            } else {
              that.$message.warning(res.msg);
            }
          })
          .catch((err) => {
            that.$message.error(err);
          });
      }
    },
    handleSelect(value) {
      this.isChoose = true;
      this.ruleForm.companyname = value.name;
    },
  },
};
</script>
<style scoped lang="less">
::v-deep .van-overlay {
  z-index: 2000;
}

.app_pop {
  height: auto;

  .dialog-main {
    padding: 0 20px;

    .dialog_fill {
      .pop_title {
        color: #2e2e2e;
        font-size: 18px;
        font-weight: 700;
        margin: 21px 0 18px 0;
      }

      .app_form {
        .formicon {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;
          padding-left: 7px;
          height: 100%;

          span:first-child {
            margin-right: 14px;
            display: flex;
          }

          span:nth-child(2) {
            width: 1px;
            height: 17px;
            background: hsla(0, 0%, 65%, 0.5);
          }
        }

        .checkCode {
          color: #5495ff;
          line-height: 48px;
        }

        ::v-deep .el-input--prefix .el-input__inner {
          height: 48px;
          padding-left: 55px;
          border-radius: 3px;
          border: 1px solid #d1d1d1;
          font-size: 14px;
          color: #a7a7a7;
        }

        ::v-deep .is-error .el-input--prefix .el-input__inner {
          border: 1px solid #f56c6c;
        }

        ::v-deep .el-form-item__content {
          margin-left: 0 !important;
        }

        ::v-deep .el-form-item {
          margin-bottom: 12px;
        }

        .submit_btn {
          height: 43px;
          width: 100%;
          margin-top: 23px;
          font-size: 14px;
          border-radius: 3px;
          background: #186cf5;
        }
      }
    }

    .dialog_success {
      .dialog_img_box {
        padding-top: 40px;
      }

      .dialog_text_box {
        color: #202124;
        margin: 28px 0 18px 0;

        .text_title {
          font-size: 18px;
          font-weight: 700;
          margin-bottom: 12px;
        }

        .text_content {
          font-size: 14px;
        }
      }

      .submit_btn {
        width: 100%;
        margin-top: 23px;
        font-size: 14px;
        border-radius: 3px;
        background: #186cf5;
      }
    }
  }
}
</style>
<style scoped lang="less">
.dialog-box {
  display: flex;
  height: 590px;
}

.middle {
  font-size: 18px;
  margin-bottom: 45px;

  img {
    width: 18px;
  }

  .middle-text {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    width: 190px;

    img {
      width: 16px;
      height: 16px;
      position: relative;
      top: 9px;
    }
  }
}

::v-deep .el-dialog__headerbtn {
  font-size: 21px;
}

.contact {
  font-size: 20px;

  img {
    width: 19px;
    margin-right: 10px;
  }
}

.left-img {
  color: #fff;
  padding: 125px 30px 110px 38px;
  box-sizing: border-box;
  width: 320px;
  background-image: url(../assets/img/blue.png);
  background-size: 100% 100%;

  .title {
    font-size: 28px;
    font-family: PingFang SC;
    font-weight: 600;
    line-height: 45px;
    color: #ffffff;
    opacity: 1;
    margin-bottom: 33px;
  }
}

.right-form {
  width: 470px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;

  .title {
    text-align: center;
    font-size: 24px;
    font-family: PingFang SC;
    font-weight: bold;
    line-height: 20px;
    margin-bottom: 25px;
    color: #018dff;
  }

  .el-button {
    width: 100%;
    margin-top: 30px;
  }

  span {
    font-size: 14px;
    cursor: pointer;
    color: #186cf5;
  }

  .start-btn {
    height: 54px;
    background: linear-gradient(90deg, #018dff 0%, #1a74f1 100%);
    //box-shadow: 0px 3px 6px 1px rgba(4, 61, 219, 0.34);
    border-radius: 5px 5px 5px 5px;
    opacity: 1;
    font-size: 16px;
  }

  .start-btn:hover {
    background: -webkit-gradient(
      linear,
      left top,
      right top,
      from(#186cf5),
      to(#095ee9)
    );
    background: linear-gradient(90deg, #186cf5, #095ee9);
  }

  .success-box {
    text-align: center;

    img {
      width: 100px;
    }

    .text-1 {
      font-size: 24px;
      color: #202124;
      margin-top: 30px;
      margin-bottom: 20px;
      box-sizing: border-box;
    }

    .text-2 {
      font-style: 16px;
      color: #202124;
      margin-bottom: 40px;
      box-sizing: border-box;
    }

    .btn {
      background: linear-gradient(90deg, #018dff 0%, #1a74f1 100%);
      border-radius: 5px 5px 5px 5px;
      width: 110px;
      height: 46px;
    }
  }
}

::v-deep .el-form-item__label {
  height: 34px;
  margin-bottom: 6px;
}

::v-deep .el-dialog {
  background: transparent;
  box-shadow: none;
}

::v-deep .el-dialog__header {
  padding: 0;
}

::v-deep .el-dialog__body {
  padding: 0 !important;
}

::v-deep .el-dialog {
  border-radius: 9px;
}

.el-autocomplete {
  width: 100%;
}

.el-form-item {
  margin-bottom: 10px;
}

::v-deep .el-form-item__error {
  display: none;
}

::v-deep .el-input__suffix {
  right: 10px;
}
</style>
